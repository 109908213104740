<script setup lang="ts">
const { toast } = defineProps<{
    toast: Toast;
}>();

const { toasts } = useToasts();

onMounted(() => {
    if (toast.duration) {
        setTimeout(() => {
            delete toasts.value[toast.key];
        }, toast.duration);
    }
});
</script>

<template>
    <div class="m-3 flex justify-center space-x-2 rounded-sm p-3 px-container-gap shadow-md typo-meta-s">
        <div class="">
            <slot name="icon" />
        </div>
        <div class="">
            <slot />
        </div>
    </div>
</template>

<script setup  lang="ts">
/**
 * The Toasts
 * --------------------------------------------------------------------------------------------------
 * Shows global warning/info/error toasts in the header of the app
 */

const { errorsOnly = false } = defineProps<{
    errorsOnly?: boolean;
}>();
const el = useTemplateRef('el');
const toastEls: Ref<ComponentPublicInstance[]> = ref([]);
const { toasts } = useToasts();

function getToastComponent(toast: Toast) {
    switch (toast.type) {
        case 'error':
            return resolveComponent('ToastError');
        case 'info':
            return resolveComponent('ToastInfo');
        case 'countdown':
            return resolveComponent('ToastCountdown');
        default:
            return null;
    }
};

const toastsFiltered = computed(() => {
    if (errorsOnly) {
        return Object.fromEntries(
            Object.entries(toasts.value).filter(([, value]) => value.type === 'error'),
        );
    }

    return toasts.value;
});
</script>

<template>
    <div ref="el" class="">
        <component
            :is="getToastComponent(toast)"
            v-for="toast in toastsFiltered"
            ref="toastEls"
            :key="toast.key"
            :toast="toast"
        />
    </div>
</template>
